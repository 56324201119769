/* eslint-disable no-restricted-globals */
export function attachWindowEvents() {
  var pushState = history.pushState;
  var replaceState = history.replaceState;

  history.pushState = function() {
    //@ts-ignore
    pushState.apply(history, arguments);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
  };

  history.replaceState = function() {
    //@ts-ignore
    replaceState.apply(history, arguments);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
  };

  window.addEventListener('popstate', function() {
    window.dispatchEvent(new Event('locationchange'));
  });
}
