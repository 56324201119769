import React from 'react';

interface IBrowserWarningPropTypes {
  text?: string;
}

function BrowserWarning({
  text = 'Supported browsers are Chrome, Firefox & Microsoft Edge. Please contact support at 800-969-7001 for any issues.',
}: IBrowserWarningPropTypes) {
  return (
    <div className="container">
      <p className="alert alert-secondary">{text}</p>
    </div>
  );
}

export default BrowserWarning;
