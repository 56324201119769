import React, { Fragment, Component, ReactNode } from 'react';
import BrowserWarning from '../Components/BrowserWarning';
import { attachWindowEvents } from './helpers/AttachWindowEvents';

attachWindowEvents();

export interface TestProps {
  token?: string;
  onLogoutClick?: () => void;
  apiUrl?: string;
  showBrowserWarning?: boolean;
  browserWarningText?: string;
  showInfoIcon?: boolean;
  onInfoIconCLicked?: () => void;
  headerChildren?: ReactNode | undefined;
}

export interface TestState {
  showSearchInput: boolean;
  searchQuery: string;
  showSubMenu: number;
  showNestedSubMenu: number;
  navMenu: [];
  location: string;
}

export interface MenuType {
  label: string;
  url: string;
  subMenu: [];
}

const NO_SUB_MENU: number = -1;

class AppContainer extends Component<TestProps, TestState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSearchInput: false,
      searchQuery: '',
      showSubMenu: NO_SUB_MENU,
      showNestedSubMenu: -NO_SUB_MENU,
      navMenu: [],
      location: window.location.href,
    };
  }

  onLocationChange() {
    this.setState({ location: window.location.href });
  }

  componentDidMount() {
    try {
      const getMenuOptions = async () => {
        const { apiUrl } = this.props;
        const domain = window.location.origin;
        const menuOptionsApiUrl =
          apiUrl && apiUrl !== '/' ? apiUrl : `${domain}/api/menu-options`;
        const response = await fetch(menuOptionsApiUrl);
        const data = await response.json();
        this.setState({ navMenu: data });
      };
      getMenuOptions();
      window.addEventListener(
        'locationchange',
        this.onLocationChange.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'locationchange',
      this.onLocationChange.bind(this)
    );
  }

  onSubmit = (e: React.SyntheticEvent) => {
    const { searchQuery, showSearchInput } = this.state;
    e.preventDefault();
    if (searchQuery && showSearchInput) {
      window.open(`https://prcousa.com/?s=${searchQuery}`, '_self');
    } else {
      this.setState({ showSearchInput: !showSearchInput });
    }
  };

  onMenuHover = (i: number) => this.setState({ showSubMenu: i });
  onMenuLeave = () => this.setState({ showSubMenu: NO_SUB_MENU });
  onSubMenuHover = (i: number) => this.setState({ showNestedSubMenu: i });
  onSubMenuLeave = () => this.setState({ showNestedSubMenu: NO_SUB_MENU });

  redirectToHome = (e: React.SyntheticEvent) => {
    e.preventDefault();
    window.history.pushState({}, '', '/');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  render() {
    const {
      children,
      headerChildren,
      token,
      onLogoutClick,
      showBrowserWarning = true,
      browserWarningText,
      showInfoIcon,
      onInfoIconCLicked,
    } = this.props;
    const {
      navMenu,
      showSubMenu,
      showNestedSubMenu,
      showSearchInput,
      searchQuery,
      location,
    } = this.state;

    const showReturnToDashboardLink =
      location.split(/\?/g)[0] !== `${window.location.origin}/`;

    return (
      <Fragment>
        <div className="header-block">
          <div className="header-block__row-container container">
            <div className="header-block__row header-block__row--main">
              <div className="header-block__column header-block__logo header-block--auto-grow">
                <style
                  data-appended-custom-css="true"
                  dangerouslySetInnerHTML={{
                    __html: '.logo-image {width:159px;height:48px;}',
                  }}
                />{' '}
                <a
                  href="/"
                  className="header-logo"
                  onClick={this.redirectToHome}
                >
                  <img
                    src="https://prcousa.com/wp-content/uploads/PRcoPowerLogorb.png"
                    className="main-logo logo-image"
                    width={159}
                    height={159}
                    alt=""
                  />
                  {showReturnToDashboardLink ? (
                    <span className="nav-link-span hover-underline-animation">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="arrow-left"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
                        ></path>
                      </svg>
                      Return to Dashboard
                    </span>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
              <div className="header-block__column header-block--content-right header-block--align-right">
                <div className="header-block__items-row">
                  <div className="header-block__item header-block__item--type-menu-main-menu header-block__item--standard-menu-container">
                    <div className="standard-menu-container menu-skin-main">
                      <nav className="nav-container-main-menu">
                        <ul id="menu-home-1" className="menu">
                          {navMenu?.length
                            ? navMenu.map((menu: MenuType, i: number) => {
                                return (
                                  <li
                                    key={i}
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item"
                                    onMouseOver={() => this.onMenuHover(i)}
                                    onMouseOut={() => this.onMenuLeave()}
                                  >
                                    <a href={menu.url}>
                                      <span className="hover-underline-animation">
                                        {menu.label}
                                      </span>
                                    </a>
                                    {menu.subMenu.length ? (
                                      <ul
                                        className="sub-menu"
                                        style={
                                          i === showSubMenu
                                            ? {
                                                opacity: 1,
                                                visibility: 'visible',
                                              }
                                            : {}
                                        }
                                      >
                                        {menu.subMenu.map(
                                          (
                                            subMenu: MenuType,
                                            index: number
                                          ) => {
                                            return (
                                              <li
                                                key={index}
                                                className={`menu-item menu-item-type-custom menu-item-object-custom menu-item ${
                                                  subMenu.subMenu.length
                                                    ? 'menu-item-has-children'
                                                    : ''
                                                }`}
                                                onMouseOver={() =>
                                                  this.onSubMenuHover(index)
                                                }
                                                onMouseOut={() =>
                                                  this.onSubMenuLeave()
                                                }
                                              >
                                                <a href={subMenu.url}>
                                                  <span className="hover-underline-animation">
                                                    {subMenu.label}
                                                  </span>
                                                </a>

                                                {subMenu.subMenu.length ? (
                                                  <ul
                                                    className="sub-menu"
                                                    style={
                                                      index ===
                                                      showNestedSubMenu
                                                        ? {
                                                            opacity: 1,
                                                            visibility:
                                                              'visible',
                                                          }
                                                        : {}
                                                    }
                                                  >
                                                    {subMenu.subMenu.map(
                                                      (
                                                        nestedSubMenu: MenuType,
                                                        index2: number
                                                      ) => {
                                                        return (
                                                          <li
                                                            key={index2}
                                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item"
                                                          >
                                                            <a
                                                              href={
                                                                nestedSubMenu.url
                                                              }
                                                            >
                                                              <span className="hover-underline-animation">
                                                                {
                                                                  nestedSubMenu.label
                                                                }
                                                              </span>
                                                            </a>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                ) : null}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : null}
                                  </li>
                                );
                              })
                            : null}
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item">
                            {!!token && (
                              <a href="#/">
                                <span
                                  data-testid="logout-btn"
                                  onClick={onLogoutClick}
                                >
                                  Logout
                                </span>
                              </a>
                            )}
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item">
                            {!!showInfoIcon && (
                              <a href="#/">
                                <span
                                  onClick={onInfoIconCLicked}
                                  className="info-btn"
                                >
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="info-circle"
                                    className="svg-inline--fa fa-info-circle fa-w-16 "
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                                    ></path>
                                  </svg>
                                </span>
                              </a>
                            )}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  {headerChildren && <div className="header-block__item">{headerChildren}</div>}
                  <div className="header-block__item header-block__item--type-search-field header-block__item--hide-on-mobile">
                    <div className="header-search-input menu-skin-main">
                      <form role="search" onSubmit={this.onSubmit}>
                        <div
                          className="search-field"
                          style={
                            showSearchInput
                              ? { opacity: 1, visibility: 'visible' }
                              : {}
                          }
                        >
                          <span></span>
                          <input
                            type="search"
                            value={searchQuery}
                            autoComplete="off"
                            name="s"
                            placeholder="Search site..."
                            onChange={e =>
                              this.setState({ searchQuery: e.target.value })
                            }
                          />
                        </div>
                        <div className="search-icon" onClick={this.onSubmit}>
                          <a href="#/" data-animation="none">
                            <svg
                              height={24}
                              width={24}
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 1417.3 1417.3"
                              // style={{ enableBackground: "new 0 0 1417.3 1417.3" }}
                              xmlSpace="preserve"
                            >
                              <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '\n\t.st0{display:none;}\n\t.st1{display:inline;}\n',
                                }}
                              />
                              <g id="search-one">
                                <path
                                  d="M976.2,925.2c90.6-98.1,147.1-229.4,147.1-374.1C1123.4,247.7,876.5,0,572.2,0S21.1,247.7,21.1,551.1
              s246.9,551.1,551.1,551.1c123.9,0,237.7-40.7,330-109.7l409.8,409.8c10,10,22.4,15,34.9,15s25.8-5,34.9-15
              c19.1-19.1,19.1-50.7,0-70.7L976.2,925.2z M120.8,551.1c0-248.6,202-451.4,451.4-451.4c248.6,0,451.4,202,451.4,451.4
              s-202,451.4-451.4,451.4S120.8,799.7,120.8,551.1z"
                                />
                              </g>
                              <g id="search-two" className="st0">
                                <path
                                  className="st1"
                                  d="M976.2,925.2c90.6-98.1,147.1-229.4,147.1-374.1C1123.4,247.7,876.5,0,572.2,0S21.1,247.7,21.1,551.1
              s246.9,551.1,551.1,551.1c123.9,0,237.7-40.7,330-109.7l409.8,409.8c10,10,22.4,15,34.9,15s25.8-5,34.9-15
              c19.1-19.1,19.1-50.7,0-70.7L976.2,925.2z"
                                />
                              </g>
                            </svg>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-container-wrapper">
          {showBrowserWarning ? (
            <BrowserWarning text={browserWarningText} />
          ) : (
            <></>
          )}
          {children}
        </div>
        <footer
          id="footer"
          role="contentinfo"
          className="site-footer main-footer footer-bottom-horizontal fixed-footer site-footer-inverted main-footer-inverted"
        >
          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-content">
                <div className="footer-content-left">
                  <div className="copyrights site-info">
                    <p>© PRco inc. {new Date().getFullYear()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export { AppContainer };
